
export function allowOnlyNumbers(e) {
    if (!(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        [8, 9, 37, 39].includes(e.keyCode) ||
        ((e.keyCode == 65 || e.keyCode == 86 || e.keyCode == 67) && (e.ctrlKey === true || e.metaKey === true))
    )) {
        e.preventDefault()
    }
}

export function roundOffValue(value){
    return Math.round(value * 100) / 100;
}